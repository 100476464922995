import { CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { F } from '@mobily/ts-belt';
import { TranslocoPipe } from '@ngneat/transloco';
import {
  BirdzTenantService,
  FilterPipe,
  FormatNumberPipe,
  SelectSearchBarComponent,
  SelectWithVirtualScrollDirective,
  Str,
} from '@web/birdz-angular';
import { ContractOption } from 'app/interfaces/contract-option.interface';
import { FilterOptions } from 'app/interfaces/filter-options.interface';
import { GeocodeOption } from 'app/interfaces/geocode-option.interface';
import { FilterService } from 'app/services/filter.service';
import { UserService } from 'app/services/user.service';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    MatOptionModule,
    MatTooltipModule,
    MatButtonModule,
    UpperCasePipe,
    TranslocoPipe,
    FilterPipe,
    SelectSearchBarComponent,
    SelectWithVirtualScrollDirective,
    FormatNumberPipe,
  ],
})
export class FilterComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  sortByLabel = Str.sortBy('label');
  sortByCityName = Str.sortBy('city_name');
  showContractFilter = false;
  isApplied: boolean = true;
  options: any = {
    contracts: [],
    geocodes: [],
  };
  // @ts-ignore
  form: FormGroup;
  isLoading = 0;
  selectedTab = 0;
  favorites$: any;
  contractOptionToLabel = (option: ContractOption) => `${option.id} - ${option.label}`;
  geocodeOptionToLabel = (option: GeocodeOption) => option.city_name;
  searchGeocodeFn: (arg0: string, arg1: any) => boolean = (search, option) =>
    Str.search(search, `${option.id} ${option.city_name}`);

  constructor(
    private filterService: FilterService,
    private fb: FormBuilder,
    private tenantService: BirdzTenantService,
    private userService: UserService
  ) {
    this.initForm();
    this.initIsApplied();
  }

  ngOnInit(): void {
    this.fetchOptions();
    this.resetWhenTenantChanges();
  }

  initIsApplied() {
    combineLatest([this.filterService.filter$, this.form.valueChanges])
      .pipe(
        map(([filter, form]) => {
          const sortedFilter = {
            contracts: filter.contracts.slice().sort(),
            geocodes: filter.geocodes.slice().sort(),
          };
          const sortedForm = {
            contracts: form.contracts.slice().sort(),
            geocodes: form.geocodes.slice().sort(),
          };
          return F.equals(sortedFilter, sortedForm);
        }),
        distinctUntilChanged(),
        tap((isApplied) => (this.isApplied = isApplied)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
  resetWhenTenantChanges() {
    this.tenantService.tenantId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.reset();
        })
      )
      .subscribe();
  }

  fetchOptions() {
    this.isLoading++;
    this.filterService
      .fetchOptions()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.autoSelectContract()),
        finalize(() => this.isLoading--),
        tap(() => this.updateOptions())
      )
      .subscribe();
  }

  initForm() {
    this.form = this.fb.group(
      {
        contracts: [],
        geocodes: [],
      },
      {
        validators: [(form: AbstractControl) => this.requirePerimeter(form)],
      }
    );
  }

  setContracts(contracts: string[]) {
    this.patchForm({ contracts });
  }

  setGeocodes(geocodes: string[]) {
    this.patchForm({ geocodes });
  }

  patchForm(patch: Record<string, any[]>) {
    this.form.patchValue(patch);
    this.updateOptions();
  }

  autoSelectContract() {
    const options = this.filterService.getOptions();
    // if only one contract, auto select it and submit filter
    if (options.contracts.length === 1) {
      this.setContracts([options.contracts[0].id]);
      this.submit();
      this.showContractFilter = false;
    } else {
      this.showContractFilter = true;
    }
  }

  updateOptions() {
    const wasContractSelected = (id: string) => this.form.get('contracts')?.value?.includes(id);
    const wasGeocodeSelected = (id: string) => this.form.get('geocodes')?.value?.includes(id);
    const fullOptions: FilterOptions = this.filterService.getOptions();
    const tenantId = this.userService.getEffectiveTenantId();
    const tenantContractIds =
      this.userService.getUser()?.getContractIdsHavingTenant(tenantId) ?? [];
    const contractOptionsUnderTenant = fullOptions.contracts.filter((c) =>
      tenantContractIds.includes(c.id)
    );
    const contractIdsUnderTenant = contractOptionsUnderTenant.map((c) => c.id);
    const geocodeOptionsUnderTenant = fullOptions.geocodes.filter((geocode) =>
      geocode.contracts.some((contractId: string) => contractIdsUnderTenant.includes(contractId))
    );
    const options: FilterOptions = {
      contracts: [],
      geocodes: [],
    };
    const form: any = {};

    // contracts
    options.contracts = contractOptionsUnderTenant.sort(this.sortByLabel);
    form.contracts = options.contracts.map((i) => i.id).filter(wasContractSelected);

    // geocodes
    const noContractSelected = !form.contracts.length;
    const someContractsOfTheGeocodeAreSelected = (geocode: GeocodeOption) =>
      geocode.contracts.some((contractId: string) => form.contracts.includes(contractId));
    const isGeocodeAvailable = (geocode: GeocodeOption) =>
      (noContractSelected || someContractsOfTheGeocodeAreSelected(geocode)) &&
      contractOptionsUnderTenant;
    options.geocodes = geocodeOptionsUnderTenant
      .filter(isGeocodeAvailable)
      .sort(this.sortByCityName);
    form.geocodes = options.geocodes.map((i) => i.id).filter(wasGeocodeSelected);

    // set options
    this.options = options;
    // set selected options
    this.form.patchValue(form);
  }

  reset() {
    this.patchForm({ contracts: [], geocodes: [] });
    this.updateOptions();
    this.autoSelectContract();
    this.submit();
  }

  submit() {
    this.filterService.setFilter({
      contracts: this.form.get('contracts')?.value,
      geocodes: this.form.get('geocodes')?.value,
    });
  }

  requirePerimeter(formGroup: AbstractControl): ValidationErrors | null {
    const contracts: any[] = formGroup.get('contracts')?.value ?? [];
    const geocodes: any[] = formGroup.get('geocodes')?.value ?? [];
    if (!contracts.length && !geocodes.length) {
      return { perimeterRequired: true };
    }
    return null;
  }
}
